import request from '@/utils/request'

export function getStaffingMethodById(data) {
  return request({
    url: `/api/StaffingMethod/GetStaffingMethodById/${data}`,
    method: 'get',
  })
}

export function getStaffingMethods() {
  return request({
    url: '/api/StaffingMethod/GetStaffingMethods',
    method: 'get',
  })
}

export function addStaffingMethod(data) {
  return request({
    url: '/api/StaffingMethod/AddStaffingMethod',
    method: 'post',
    data,
  })
}

export function updateStaffingMethod(data) {
  return request({
    url: '/api/StaffingMethod/UpdateStaffingMethod',
    method: 'put',
    data,
  })
}
export function deleteStaffingMethod(data) {
  return request({
    url: `/api/StaffingMethod/DeleteStaffingMethod?staffingMethodId=${data}`,
    method: 'delete',
  })
}
